import { useTrackContactVendor } from '@hooks/useTrackContactVendor';
import { useTrackContactVendorInitiated } from '@hooks/useTrackContactVendorInitiated';
import { updateAllFieldsChange } from '@redux/rfq/actionCreators';
import { sendInquiryLead } from '@redux/rfq/thunks';
import type { InlineRfqSourceContent } from '@typings/sourceContent';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { State } from 'types/redux';
import type {
	InlineFieldKeys,
	InlineFieldShape,
	InlineFields,
} from 'types/rfq';
import type { Decorated, Raw } from 'types/vendor';
import RecommendedContext from '../../../../../../contexts/RecommendedContext/RecommendedContext';
import PageType = Page.PageType;
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { afterNextPaint } from '../../../../../../../helpers/after-next-paint';
import { useTrackInlineForm } from '../useTrackInlineForm/use-track-inline-form';
import { useInlineRfqSubheaderTextOverride } from './use-inline-rfq-subheader-text-override';

export type LocalFormType = InlineFields & {
	isDateFlexible: boolean;
};
type StandardFieldType = Parameters<
	(field: InlineFieldShape, name: InlineFieldKeys) => void
>;
type FlexibleFieldType = Parameters<
	(field: boolean, name: 'isDateFlexible') => void
>;
type HandleSubmit = () => Promise<boolean>;

const getAreErrorsInForm = (values: LocalFormType) =>
	Object.keys(values).some((field: InlineFieldKeys) => values[field].error);

const useHandleSubmit = (
	closeModal: () => void,
	initiator: InlineRfqSourceContent,
	vendor: Decorated | null,
	pageType: PageType,
	getExtraProperties?: () => object,
	values?: LocalFormType,
) => {
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [readyToSubmit, setReadyToSubmit] = useState(false);
	const { openModal: openVrmModal } = useContext(RecommendedContext);
	const dispatch = useDispatch();
	const trackSubmit = useTrackInlineForm('submit', initiator);
	const trackContactVendor = useTrackContactVendor({
		vendor,
		pageType,
		sourceContent: initiator,
		getExtraProperties,
	});

	const handleSubmit: HandleSubmit = useCallback(async () => {
		setIsSubmitting(true);
		closeModal();

		const conversationId = await dispatch(sendInquiryLead(initiator));

		const context = {};
		if (values) {
			const { firstName, lastName, emailAddress, phoneNumber } = values;
			const traits = {
				firstName: firstName.value,
				lastName: lastName.value,
				email: emailAddress.value,
				phone: phoneNumber.value !== '' ? phoneNumber.value : undefined,
			};

			Object.assign(context, { traits });
		}

		trackSubmit();
		setIsSubmitting(false);
		setReadyToSubmit(false);

		if (conversationId) {
			trackContactVendor(conversationId, context);
			openVrmModal(initiator);
		}
		return conversationId !== undefined;
	}, [
		trackContactVendor,
		trackSubmit,
		closeModal,
		openVrmModal,
		initiator,
		dispatch,
		values,
	]);

	return {
		handleSubmit,
		isSubmitting,
		setReadyToSubmit,
		readyToSubmit,
	};
};

const getInitialValues = (
	initialValues: LocalFormType,
	values: LocalFormType,
	textAreaInitialValueOverride?: InlineFieldShape,
) => {
	const textareaOverride = textAreaInitialValueOverride
		? { textarea: textAreaInitialValueOverride }
		: {};

	const isDateFlexible = initialValues.isDateFlexible || values.isDateFlexible;

	return {
		...initialValues,
		...textareaOverride,
		isDateFlexible,
	};
};

export interface useInlineRfqFormProps {
	freeTextLabel?: string;
	headerText?: string;
	subheaderText?: JSX.Element | string;
	textAreaInitialValueOverride?: InlineFieldShape;
	initiator: InlineRfqSourceContent;
	getExtraProperties?: () => object;
	additionalInitiatedTrackingProps?: object;
}
export const useInlineRfqForm = ({
	freeTextLabel,
	headerText,
	textAreaInitialValueOverride,
	initiator,
	getExtraProperties,
	additionalInitiatedTrackingProps,
	subheaderText,
}: useInlineRfqFormProps) => {
	const dispatch = useDispatch();

	const inlineFormState = useSelector((state: State) => state.rfq.inline);
	const { vendor, vendorRaw } = useSelector((state: State) => state.vendor);
	const currentBadges = useSelector((state: State) => state.rfq.currentBadges);
	const pageType = useSelector((state: State) => state.page.pageType);

	const [shouldShowErrors, setShouldShowErrors] = useState(false);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [values, setValues] = useState({
		...inlineFormState.fields,
		isDateFlexible: inlineFormState.isDateFlexible,
	});
	const isInDetailedPricing = useDetailedPricing();
	const subheaderTextOverride = useInlineRfqSubheaderTextOverride(headerText);
	const freeTextLabelOverride = isInDetailedPricing
		? 'Say hello and ask a question about style, budget or availability.'
		: freeTextLabel;

	const trackContactVendorInitiated = useTrackContactVendorInitiated({
		pageType,
		currentBadges,
		sourceContent: initiator,
		additionalInitiatedTrackingProps,
	});

	const trackInitialForm = useTrackInlineForm('initial', initiator);

	const handleCtaClick = useCallback(() => setShouldShowErrors(true), []);
	const { handleSubmit, isSubmitting, readyToSubmit, setReadyToSubmit } =
		useHandleSubmit(
			() => {
				setIsModalOpen(false);
			},
			initiator,
			vendor,
			pageType,
			getExtraProperties,
			values,
		);
	const areErrorsInForm = useMemo(() => getAreErrorsInForm(values), [values]);

	const setInitialValues = useCallback(
		(initialValues: LocalFormType) => {
			const newValues = getInitialValues(
				initialValues,
				values,
				textAreaInitialValueOverride,
			);

			setValues((oldValues) => ({
				...oldValues,
				...newValues,
			}));
		},
		[textAreaInitialValueOverride, values],
	);

	const setFieldValue = useCallback(
		(...args: StandardFieldType | FlexibleFieldType) => {
			const [field, name] = args;
			setShouldShowErrors(false);

			setValues((oldValues) => ({
				...oldValues,
				[name]: field,
			}));
		},
		[],
	);

	const openModal = useCallback(
		(vendorArg?: Decorated, vendorRawArg?: Raw) => {
			afterNextPaint(() => {
				setIsModalOpen(true);
				trackContactVendorInitiated({
					vendor: vendorArg?.id ? vendorArg : vendor,
					vendorRaw: vendorRawArg?.id ? vendorRawArg : vendorRaw,
				});
				trackInitialForm(
					vendorArg?.id ? vendorArg : vendor,
					vendorRawArg?.id ? vendorRawArg : vendorRaw,
				);
			});
		},
		[trackContactVendorInitiated, trackInitialForm, vendor, vendorRaw],
	);

	useEffect(() => {
		if (!areErrorsInForm && shouldShowErrors) {
			setReadyToSubmit(true);
			dispatch(updateAllFieldsChange(values));
		}
	}, [shouldShowErrors, areErrorsInForm, dispatch, setReadyToSubmit, values]);

	useEffect(() => {
		if (!isModalOpen) {
			setShouldShowErrors(false);
			setReadyToSubmit(false);
		}
	}, [setReadyToSubmit, isModalOpen]);

	return useMemo(
		() => ({
			values,
			shouldShowErrors,
			areErrorsInForm,
			readyToSubmit,
			handleSubmit,
			isSubmitting,
			isModalOpen,
			freeTextLabel: freeTextLabelOverride || freeTextLabel,
			headerText,
			subheaderText: subheaderTextOverride || subheaderText,
			initiator,
			pageType,
			handleCtaClick,
			setFieldValue,
			setInitialValues,
			setIsModalOpen,
			openModal,
			getExtraProperties,
		}),
		[
			values,
			shouldShowErrors,
			areErrorsInForm,
			readyToSubmit,
			handleSubmit,
			isSubmitting,
			isModalOpen,
			freeTextLabel,
			headerText,
			subheaderText,
			initiator,
			pageType,
			handleCtaClick,
			setFieldValue,
			setInitialValues,
			openModal,
			getExtraProperties,
			freeTextLabelOverride,
			subheaderTextOverride,
		],
	);
};

export type UseInlineRfqFormReturn = {
	values: LocalFormType;
	shouldShowErrors: boolean;
	areErrorsInForm: boolean;
	readyToSubmit: boolean;
	handleSubmit: HandleSubmit;
	isSubmitting: boolean;
	isModalOpen: boolean;
	freeTextLabel: string | undefined;
	headerText: string | undefined;
	subheaderText: string | undefined;
	initiator: InlineRfqSourceContent;
	handleCtaClick: () => void;
	pageType: PageType;
	setFieldValue: (...args: StandardFieldType | FlexibleFieldType) => void;
	setInitialValues: (initialValues: LocalFormType) => void;
	setIsModalOpen: (isOpen: boolean) => void;
	openModal: () => void;
	getExtraProperties: (() => object) | undefined;
};
